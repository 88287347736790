body {
    min-height: 600px;
    padding-top: 10px;
    margin-top: 50px;
}
.mygrid-wrapper-div {
    height: 400px !important;
    overflow: scroll;
}
.searchform {
    max-width: 800px;
    border: 1px solid #ccc;
    padding: 4px;
    margin: 0 auto;
}
.alert-messages {
    position: fixed;
    top: 47px;
    left: 0;
    right: 0;
    z-index: 7000;
}
/* ajax loader */

.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba( 255, 255, 255, .8) url('/img/loader_brown.gif') 50% 50% no-repeat;
}
/* When the body has the loading class, we turn
   the scrollbar off with overflow:hidden */

body.loading {
    overflow: hidden;
}
/* Anytime the body has the loading class, our
   modal element will be visible */

body.loading .modal {
    display: block;
}
/* ajax loader end */

.ewc-footer {
    clear: both;
    font-size: 10px;
    margin-top: 20px;
    padding: 5px;
    color: #777;
    border-top: 1px solid #e5e5e5;
}
.pageheader {
    color: #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid #e9e9e9;
}
.pageheader h3 {
    display: inline;
    padding-left: 15px;
}
.green {
    color: green;
}
.red {
    color: red;
}
.orange {
    color: orange;
}
.ccc {
    color: #ccc;
}
.brown {
    color: brown;
}
.mainpage {
    overflow: hidden;
    margin: 0 auto;
}
.mainpage ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.mainpage li {
    float: left;
    display: block;
    text-align: center;
    padding: 18px;
    margin: 10px;
}
.promo_ztitle {
    clear:both;
    display: block;
	color: #333;
	max-width: 700px;
	padding: 10px;
	border: 1px solid #ccc;
	background: #f3f2eb;
}
.promo_imgsblock {
	color: #333;
	width: 100%;
	height: 160px;
	padding: 10px;
}
.promo_thumb {
	overflow: hidden;
	float: left;
	width: 140px;
	height: 140px;
	border-radius: 4px;
	border: 1px solid #ccc;
	margin-left: 12px;
	position: relative;
}
.promo_price {
	position: absolute;
	top: 5px;
	right: 0px;
	padding: 2px;
	padding-left: 4px;
	padding-right: 4px;
	margin-right: -1px;
	font-size: 10px!important;
	border-radius: 2px;
	border-right: 1px solid #ccc;
	border-bottom: 1px solid #ccc;
	color: #fff!important;
	background: rgba(187, 0, 0, 0.75);
	font-family: "Verdana", serif;
}
.promo_title {
	position: absolute;
	width: 100%;
	height: 34px;
	line-height: 13px;
	bottom: 0;
	padding: 4px;
	margin-left: 0px;
	font-size: 10px!important;
	border-top: 1px solid #ccc;
	color: #fff!important;
    background: rgba(0, 0, 0, 0.75);
}


